.textView {
  padding: 6% 6% 9% 6%;
  height: 100%;
  overflow-y: auto;
}

.footer {
  position: fixed;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  right: 0;
  height: 6%;
  padding-bottom: 6%;
}
