*:focus {
  outline: rgba(0, 0, 0, 0);
  outline: 0;
  outline: none;
}

:focus {
  outline: 0;
  outline: none;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Roboto */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
}

/* Monospace Sans Serif */
@font-face {
  font-family: 'Andale Mono';
  src: url('./fonts/AndaleMono.ttf') format('truetype');
}

/* Cursive */
@font-face {
  font-family: 'Pacifico';
  src: url('./fonts/Pacifico.ttf') format('truetype');
}

/* Casual */
@font-face {
  font-family: 'Comic Sans MS';
  src: url('./fonts/ComicSansMS.ttf') format('truetype');
}

/* Monospace Serif */
@font-face {
  font-family: 'Courier New';
  src: url('./fonts/CourierNew.ttf') format('truetype');
}

/* Proportionnal Serif */
@font-face {
  font-family: 'Times New Roman';
  src: url('./fonts/TimesNewRoman.ttf') format('truetype');
}

html,
body {
  margin: 0;
  overflow: hidden;
  color: #f7f7f7;
  position: relative;

  background-color: rgb(11, 17, 32);
  background-size: cover;
  background-position: center;

  height: 100vh;
  width: 100vw;
  font-family: 'Roboto', sans-serif;
  font-size: 90%;
}

@media (min-width: 960px) {
  html,
  body {
    font-size: 95%;
  }
}

@media (min-width: 1280px) {
  html,
  body {
    font-size: 110%;
  }
}

@media (min-width: 1920px) {
  html,
  body {
    font-size: 135%;
  }
}

@media (min-width: 3840px) {
  html,
  body {
    font-size: 190%;
  }
}

button {
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
}

h1 {
  font-size: 3.5em;
}

h2 {
  font-size: 2.5em;
}

h3 {
  font-size: 2em;
}

video::-webkit-media-text-track-container {
  padding-bottom: 3em;
}
